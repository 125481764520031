import { IBareItem, KeyedCollection } from "./keyed-collection";

export interface IEndpoint {
  children?: IEndpoint[];
  id: string;
  name: string;
  properties?: KeyedCollection<IBareItem>;
}

export abstract class Endpoint implements IEndpoint {
  constructor( public id: string, public name: string, public children?: IEndpoint[], public properties?: KeyedCollection<IBareItem> ) {
    if (!this.properties) {
      this.properties = new KeyedCollection(properties);
    }
    if (!this.children) {
      this.children = [] as IEndpoint[];
    }
  }
}

export class PouchDocument {
  constructor(public key: string, public _id: string, public value: any, public doc: Endpoint, public _rev?: string) {}
}
