import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { Logger } from "../shared/Logger";

import { MatDivider, MatSidenav,  MatToolbar } from "@angular/material";

import * as log4js from "log4js";

@Component( {
  providers: [
  ],
  selector: "json-viewer",
  templateUrl: "./json.component.html",
  styleUrls: ["./json.component.css"]
} )

export class JsonComponent implements OnInit {
  @Input() value: any;
  @Input() level: number;
  public expanded: boolean = false;
  public selected: any = null;
  public selectedvalue: any = null;
  private logger: Logger = new Logger(module.id.split(/\//g).pop());

  constructor(private elementRef: ElementRef) {
    this.logger.debug(`initializing ${module.id.split(/\//g).pop()}`);
  }
  ngOnInit() {
    ++this.level;
    this.logger.debug(`value is ${JSON.stringify(this.value)}`);
    this.logger.debug(`keys is ${JSON.stringify(this.keys())}`);
    this.logger.debug(`values is ${JSON.stringify(this.values())}`);
  }
  keys(): string[] {
    return Object.keys(this.value);
  }
  values(): any[] {
    return Object.keys(this.value).map(key => this.value[key]);
  }
  public isObject(key: string): boolean {
    if (this.keys().indexOf(key) === -1) {
      this.logger.warn(`Trouble here.  caonnot locate ${key} in ${this.keys}`);
      return false;
    } else {
      if (!this.values()[this.keys().indexOf(key)]) {
        return false;
      }
      return typeof this.values()[this.keys().indexOf(key)] === "object";
    }
  }
  public select(key: string) {
    this.selected = key;
    this.selectedvalue = this.values()[this.keys().indexOf(key)];
    this.logger.debug(`select (${key}: ${this.selectedvalue})`);
  }
  public deselect(key: string) {
    this.selected = null;
    this.value = null;
  }
  public focusOut() {
    this.logger.debug(`focusOut`);
  }
}
