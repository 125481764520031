import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";

//import "rxjs/add/operator/toPromise";

import { Endpoint, PouchDocument } from "../shared/endpoint";
import { KeyedCollection } from "../shared/keyed-collection";
import { Logger } from "../shared/Logger";

@Injectable()
export class EndpointService {
  private logger: Logger = new Logger(module.id.split(/\//g).pop());
  private base: string = "./assets";
  constructor(private http: HttpClient) {
    // console.log(`constructor() with base ${module.id}`);
  }

  public get(endpointType: string): Promise<any> {
    return new Promise((resolve) => {
      // console.log(`getting ${this.base}${endpointType}`);
      this.http.get<PouchDocument[]>(`${this.base}${endpointType}`)
      .toPromise()
      .then( (response: any[]) => {
        // console.log(`response is ${JSON.stringify(response)}`);
        let endpoints: any[] = [];
        endpoints.push(response);
        resolve(endpoints);
      })
      .catch((err: Error) => {
        console.log(`Error: ${err} / ${JSON.stringify(err)}`);
      });
    });
  }
  public post(endpointOut: PouchDocument): Promise<any> {
    return new Promise((resolve) => {
      this.logger.debug(`posting ${this.base}/${JSON.stringify(endpointOut)}`);
      this.http.post<PouchDocument>(`${this.base}/${endpointOut.key}`, endpointOut)
      .toPromise().then( (response: PouchDocument) => {
        this.logger.debug(`POST ${endpointOut} Received ${JSON.stringify(response)}`);
        const endpoint: Endpoint = { children: response.doc.children, id: response.doc.id, name: response.doc.name, properties: new KeyedCollection(response.doc.properties) } as Endpoint;
        const newMenu: PouchDocument = new PouchDocument(response.key, response.key, response.value, endpoint, response._rev ? response._rev : response.value ? response.value.rev : null);
        this.logger.debug(`RESOLVING: ${JSON.stringify(newMenu)}`);
        resolve(newMenu);
      });
    });
  }
  public delete(endpointOut: PouchDocument): Promise<any> {
    return new Promise((resolve) => {
      // DELETING /${obj.doc._id}?rev=${obj.doc._rev}
      this.logger.debug(`deleting ${this.base}/${endpointOut._id}?rev=${endpointOut._rev} => ${JSON.stringify(endpointOut)}`);
      this.http.delete(`${this.base}/${endpointOut._id}?rev=${endpointOut._rev}`)
      .toPromise().then( (response: HttpResponse<Endpoint>) => {
        this.logger.debug(`DELETE ${endpointOut} Received ${JSON.stringify(response)}`);
        let endpoint: Endpoint = response.body;
        this.logger.debug(`resolving parent ${JSON.stringify(endpoint)}`);
        resolve(endpoint);
      });
    });
  }
  public put(endpoint: PouchDocument): Promise<any> {
    this.logger.debug(`Updatating ${JSON.stringify(endpoint)}`);
    return new Promise((resolve) => {
      this.logger.debug(`putting ${this.base}/${endpoint._id}?rev=${endpoint._rev}`);
      this.http.put(`${this.base}/${endpoint._id}?rev=${endpoint._rev}`, endpoint)
      .toPromise().then( (response) => {
        this.logger.debug(`PUT ${endpoint} Received ${JSON.stringify(response)}`);
        endpoint
        resolve(endpoint);
      });
    });
  }
}
