import { Dictionary } from "./Dictionary";
import { Services } from "./Service";

export interface IEnvironment {
  name: string;
  services: Services;
}
export class Environments extends Dictionary<IEnvironment>  {
  constructor() {
    super();
  }
}
