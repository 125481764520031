import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MatCardModule, MatDividerModule, MatExpansionModule, MatInputModule, MatListModule, MatSelectModule, MatSidenavModule,  MatTabsModule, MatTableModule, MatToolbarModule, MatTooltipModule } from "@angular/material";

import { MatIconModule } from "@angular/material/icon";

import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from "./app.component";
import { ConfirmComponent } from "./confirm/confirm.component";
import { DialogComponent } from "./dialog/dialog.component";
import { EndpointService } from "./service/endpoint.service";
import { JsonComponent } from "./components/json.component";

import { AppRoutingModule } from "./app.routing";

@NgModule({
  declarations: [
    AppComponent,
    ConfirmComponent,
    DialogComponent,
    JsonComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  providers: [ EndpointService ],
  bootstrap: [ AppComponent ],
})
export class AppModule { }
