import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppComponent } from "./app.component";
import { JsonComponent } from "./components/json.component";

const appRoutes: Routes = [
  { path: "app", component: AppComponent },
  { path: "json", component: JsonComponent }
];

@NgModule( {
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forRoot( appRoutes )
  ]
} )

export class AppRoutingModule { }
