import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["../dialog/dialog.component.css"]
})
export class ConfirmComponent implements OnInit {
  @Input() visible: boolean;
  @Input() title: string;
  @Input() question: string;
  @Input() okText: string;
  @Input() cancelText: string;
  @Output() valueEmitted = new EventEmitter<boolean>();

  constructor() {
    this.okText = "OK";
    this.cancelText = "Cancel";
  }

  ngOnInit() {
  }

  emitValue(value: boolean) {
    this.valueEmitted.emit(value);
    this.visible = false;
  }

}
