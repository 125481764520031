export abstract class Dictionary<T> implements Iterable<any> {
  private items: { [index: string]: any } = {};

  [Symbol.iterator]() {
    let pointer: number = 0;
    let items: any = this.items;
    return {
      next(): IteratorResult<any> {
        if (pointer < Object.keys(this.items).length) {
          return {
            done: false,
            value: this.items[pointer++]
          }
        } else {
          return {
            done: true,
            value: null
          }
        }
      }
    }
  }
  public add(item: any): any {
    this.items[item.name] = item;
    return this.items[item.name];
  }
  public get(name: string): any {
    return this.items.find((item) => item.name === name);
  }
}
