// import * as util from "util";

export class Logger {
  constructor(public name: string, private options?: any) {
    if (!this.options) {
      this.options = { debug: false, info: true, error: true, trace: false, warn: true};
    }
  }
  public debug(message: string): void {
    if (this.options.debug) {
      this.log("DEBUG", message);
    }
  }
  public info(message: string): void {
    if (this.options.info) {
      this.log("INFO", message);
    }
  }
  public warn(message: string): void {
    if (this.options.warn) {
      this.log("WARN", message);
    }
  }
  public error(message: string): void {
    if (this.options.error) {
      this.log("ERROR", message);
    }
  }
  public inspect(obj: any): string {
    return JSON.stringify(obj);
  }
  private log(type: string, message: string) {
    let myline: string = null;
    const trace: string[] = new Error().stack.split(/\n/g);
    if (trace && trace.length) {
      trace.shift();
      // Get the 1st line without this file name present
      // console.log(`${util.inspect(trace)}`);
      for (const line in trace) {
        if (trace[line].indexOf(module.id.split(/\//g).pop()) === -1) {
          // console.log(`FOUND: ${trace[line]}`);
          myline = trace[line];
          break;
        }
      }
    }
    let obj: any = null;
    if (myline) {
      const results: string[] = new RegExp(/at ([^\(]+)\(/).exec(myline);
      if (results && results.length) {
        obj = results[1];
      }
    }
    console.log(`${this.name} [${type}] ${obj ? obj : ""}: ${message}`);
  }
}
