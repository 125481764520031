import { Component, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { Router } from "@angular/router";

import { environment } from "../environments/environment";

import { EndpointService } from "./service/endpoint.service";
import { Environments, IEnvironment } from "./shared/Environment";
import { Endpoint, PouchDocument } from "./shared/endpoint";
import { Services, IService } from "./shared/Service";
import { IBareItem, KeyedCollection } from "./shared/keyed-collection";
import { Logger } from "./shared/Logger";

import { MatDivider, MatIcon, MatSidenav,  MatToolbar } from "@angular/material";

import * as log4js from "log4js";

@Component( {
  providers: [
    EndpointService
  ],
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
} )

export class AppComponent implements OnInit {
  private lastpropertykey: string = null;
  private isJson: boolean = false;
  private logger: Logger = new Logger(module.id.split(/\//g).pop());
  public showDialog: boolean = false;
  public showPropertyDialog: boolean = false;
  public asking: boolean = false;
  public question: string = "";
  public editingEndpoint: any = null;
  public fieldValue: string = "";
  public selected: any;
  public selectedIndex: number = -1;
  public status: string = "Ready!";
  public child: Endpoint = null;
  public okButtonText: string = "";
  public displayedColumns = [ "key", "value" ];
  public dataSource: IBareItem[] = null;
  public selectedProperty: IBareItem = null;
  public addingChild: boolean = false;
  public addingProperty: boolean = false;
  public editInPlace: boolean = false;
  public editingProperty: boolean = false;
  public bio: any = {};
  public items: any = [];
  public data: any;
  public environment: any = environment;
  public screenWidth: number;
  public opened: boolean = true;
  
  constructor( private endpointService: EndpointService, private router: Router ) {
    this.logger.debug(`initializing env:${environment.name} : ${module.id.split(/\//g).pop()}`);
    this.screenWidth = window.innerWidth;
    this.opened = true;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.opened = true;
    };
  }
  ngOnInit() {
    const e: Environments = new Environments();
    const x: IEnvironment = { name: "test", services: null } as IEnvironment;
    const y: IEnvironment = e.add(x);
    this.logger.debug(`Environments: ${this.logger.inspect(e)}`);
    this.logger.debug(`Environment: ${this.logger.inspect(y)}`);
    this.endpointService.get("/bio.json")
    .then((bio: any) => {
      this.bio = bio[0];
      this.logger.debug(`this.bio = ${JSON.stringify(this.bio)}`);
      this.endpointService.get("/items.json")
      .then((items: any) => {
        this.logger.debug(`items: ${JSON.stringify(items)}`);
        this.items = items[0];
        this.select(this.items[2]);
      });
    });
  }
  public toggleSideNav(): boolean {
    this.opened = !this.opened;
    return this.opened;
  }
  public isSelected(name: string): string {
    return this.selected && this.selected.doc.name === name ? "lightblue" : "inherited";
  }
  itemCount() {
    if ( !!this.selected && this.selected.doc.children ) {
      return this.selected.doc.children.length;
    } else {
      return 0;
    }
  }
  /**
   * Add a new endpoint.
   *
   */
  newEndpointDialog() {
    this.logger.debug(`newEndpointDialog()`);
    this.fieldValue = "";
    this.okButtonText = `Add Endpoint`;
    this.showDialog = true;
  }
  newPropertyDialog() {
    this.logger.debug(`newPropertyDialog()`);
    this.fieldValue = "";
    this.showDialog = true;
    this.showPropertyDialog = true;
    this.addingProperty = true;
    this.okButtonText = `Add Property`;
  }
  editSelectedEndpoint() {
    this.logger.debug(`editSelectedEndpoint(): ${JSON.stringify(this.selected)}`);
    if (this.selected) {
      this.editingEndpoint = this.selected;
      this.fieldValue = this.selected.key;
      this.okButtonText = `Update Endpoint`;
      this.showDialog = true;
    }
  }
  saveChangedProperty() {
    this.logger.debug(`saveChangedProperty ${JSON.stringify(this.selectedProperty)}`);
    if (this.selectedProperty) {
      this.logger.debug(`ENDPOINT: ${this.logger.inspect(this.selected)}`);
      this.logger.debug(`PROPERTIES: ${this.logger.inspect(this.selected.doc.properties._items)}`);
      this.endpointService.put(this.selected)
    } else {
      this.logger.debug(`no property selected`);
    }
  }
  hideDialog() {
    this.showDialog = false;
    this.editingEndpoint = null;
    this.fieldValue = null; // make sure Input is new
  }
  askQuestion() {
    if (this.selected) {
      return `${this.question} ${this.selected.key}?`;
    } else {
      return `Nothing is selected`;
    }
  }
  promptToDelete(item: any) {
    this.logger.debug(`promptToDelete ${item.key}`);
    this.question = "Delete";
    this.asking = true;
  }
  /**
   * Remove the selected endpoint and children
   */
  removeSelected() {
    this.logger.debug(`removeSelected ${JSON.stringify(this.selected)}`);
    this.logger.debug(`this.items = ${JSON.stringify(this.items)}`);
    if ( this.selected && this.items) {
      let index: number = this.items.length - 1;
      while ( index >= 0 ) {
        this.logger.debug(`index is ${index}`);
        this.logger.debug(`comparing ${JSON.stringify(this.selected)}.key with ${JSON.stringify(this.items[index])}.key`);
        if ( this.selected.key === this.items[index].key ) {
          this.logger.debug(`removing ${this.selected.key}`);
          this.logger.debug(`Notifying service ... to delete ${JSON.stringify(this.selected)}`);
          this.endpointService.delete(this.selected);
          this.selected = null;
          this.status = "Ready";
          this.child = null;
          this.items.splice( index, 1 );
          break;
        } else {
          this.logger.debug(`ignoring ${this.items[index].key}`);
          --index;
        }
      }
    }
  }

  questionConfirmed ( answer: boolean ) {
    this.logger.debug(`questionConfirmed: ${answer}`);
    if (!!answer) {
      switch (this.question) {
        case "Delete":
          this.removeSelected();
          break;
        default:
          this.logger.debug(`Don't know what to do with ${this.question}`);
          break;
      }
    } else {
      this.logger.debug(`questionConfirmed: cancelled`);
    }
    this.logger.debug(`Closing confirm dialog`);
    this.asking = false;
  }
  showProperties() {
    if (this.selected) {
      if (!this.dataSource) {
        this.logger.debug(`Setting dataSource to ${this.selected.doc.properties.toArray()}`);
        this.logger.debug(`this.selected.doc.properties is ${JSON.stringify(this.selected.doc.properties)}`);
        this.logger.debug(`as an array: ${JSON.stringify(this.selected.doc.properties.toArray())}`);
        this.dataSource = this.selected.doc.properties.toArray();
      } else {
        this.dataSource = null;
      }
    }
    this.logger.debug(`dataSource : ${JSON.stringify(this.dataSource)}`);
  }
  public selectChild( endpoint: Endpoint, event: any ) {
    this.logger.debug( `selectChild(${JSON.stringify(endpoint)})` );
    if (this.child && this.child.name === endpoint.name) {
      // this.editInPlace = true;
    } else {
      this.editInPlace = false;
    }
    this.child = endpoint;
    if (!this.child.properties) {
      this.child.properties = this.generateChildProperties();
    }
    if (this.child.properties.constructor.name === "Object") {
      this.logger.debug(`child.properties is ${this.child.properties.constructor.name}`);
      this.child.properties = new KeyedCollection(this.child.properties);
    }
    this.child.properties.add("selected", true);
    this.logger.debug( `children: ${JSON.stringify( this.child.children )}` );
    if (event) {
      this.logger.debug(`stopping event propagation`);
      event.stopPropagation();
    }
  }
  public disableTooltip( endpoint: Endpoint ) {
    return this.selected && this.selected.key == endpoint.name ? false : true;
  }
  public select( endpoint: any ) {
    if (!endpoint) {
      return;
    }
    this.logger.debug(`select ${JSON.stringify(endpoint)}`);
    this.selected = endpoint;
    this.logger.debug(`selected is ${JSON.stringify(this.selected)}`);
    this.status = endpoint.key;
    this.child = null;
    this.dataSource = null;
    this.endpointService.get(this.selected.url)
    .then((response: any) => {
      this.data = response[0];
    });
  }
  public dialogTitle() {
    let action: string = "New";
    if (this.editingEndpoint) {
      action = "Edit";
    }
    if (this.addingChild) {
      return `${action} ${this.status}`;
    } else {
      if (this.addingProperty) {
        return `${action} Property`;
      } else {
        return `${action} Endpoint`;
      }
    }
  }
  public handleRowClick(row: any) {
    this.logger.debug(`handleRowClick(${JSON.stringify(row)})`);
    if (!this.editInPlace) {
      if (this.selectedProperty) {
        if (row.key === this.selectedProperty.key) {
          this.editInPlace = true;
        } else {
          // update the previously changed property
 //         this.logger.debug(`Update ${this.logger.inspect(this.selectedProperty)} from ${this.selected.doc.properties._items[this.lastpropertyname]}`);
          this.editInPlace = false;
          this.selectedProperty = row;
 //         this.lastrow = row;
          this.lastpropertykey = row.key;
        }
      } else {
        this.selectedProperty = row;
 //       this.lastrow = row;
        this.lastpropertykey = row.key
        this.editInPlace = false;
      }
    } else {
      if (this.selectedProperty) {
        if (this.selectedProperty.key !== row.key) {
          this.editInPlace = false;
          this.selectedProperty = row;
 //         this.lastrow = row;
          this.lastpropertykey = row.key;
        }
      }
    }
  }
  updateSelectedProperty( name: string ) {
    this.logger.debug(`updateSelectedProperty ${name}: addingProperty = ${this.addingProperty}`);
  }
  removeSelectedProperty() {
    this.logger.debug(`removeSelectedProperty()`);
  }
  private generateChildProperties() {
    const props: KeyedCollection<any> = new KeyedCollection<any>({ _items: { selected: "false", virtual: "false"}, __count: 2 } as KeyedCollection<any>);
    return props;
  }
  public json() {
    this.isJson = !this.isJson;
    this.logger.debug( `json(${this.isJson})` );
    this.router.navigate( ["/json"] );
  }
}
