export interface IKeyedCollection {
  add?( key: string, value: any ): void;
  containsKey?( key: string ): boolean;
  count?(): number;
  item?( key: string ): any;
  keys?(): string[];
  remove?( key: string ): string;
  values?(): any[];
}
export interface IBareItem {
  [key: string]: string;
  value: any;
}

export class KeyedCollection<T> implements IKeyedCollection {
  public _items: { [index: string]: any } = {};
  public __count: number = 0;

  constructor(collection?: KeyedCollection<T>) {
    console.log(`${module.id.split(/\//g).pop()} received ${JSON.stringify(collection)}`);
    console.log(`KeyedCollection => collection is ${ typeof collection }`);
    if (!!collection) {
//      console.log(`collection.isArray = ${collection.isArray}`);
//      console.log(`collection instanceof Array = ${collection instanceof Array}`);
//      console.log(`collection.length = ${collection.length}`);
      if (collection instanceof Array) {
        collection.forEach((item: any) => {
          Object.keys(item).forEach((key) => {
            this.add(key, item[key]);
          });
        });
      } else {
        console.log(`trying to get object/keys from ${JSON.stringify(collection)}`);
        Object.keys(collection._items).forEach((key) => {
          console.log(`KEY is ${key}`);
          this.add(key, collection._items[key]);
        });
      }
    }
  }
  public containsKey?( key: string ): boolean {
    return this._items.hasOwnProperty( key );
  }

  public count?(): number {
    return this.__count;
  }

  public add?( key: string, value: any ) {
    if ( !this._items.hasOwnProperty( key ) ) {
      this.__count++;
    }
    this._items[key] = value;
  }

  public remove?( key: string ): string {
    let val: string = this._items[key];
    delete this._items[key];
    this.__count--;
    return val;
  }

  public item?( key: string ): any {
    return this._items[key];
  }

  public keys?(): string[] {
    let keySet: string[] = [];

    for ( const prop in this._items ) {
      if ( this._items.hasOwnProperty( prop ) ) {
        keySet.push( prop );
      }
    }
    return keySet;
  }

  public values?(): any[] {
    const values: any[] = [];

    for ( const prop in this._items ) {
      if ( this._items.hasOwnProperty( prop ) ) {
        values.push( this._items[prop] );
      }
    }
    return values;
  }
  public toArray?(): IBareItem[] {
    const ary: IBareItem[] = [];
    for (const key in this._items) {
      if (this._items.hasOwnProperty(key)) {
        ary.push({ key: key, value: this.item(key) });
      }
    }
    return ary;
  }
}
