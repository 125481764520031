import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"]
})
export class DialogComponent implements OnInit {
  @Input() value: string;
  @Input() valuevalue: string;
  @Input() showPrompt: boolean;
  @Input() showChild: boolean;
  @Input() showValue: boolean;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() template: string;
  @Input() valuetemplate: string;
  @Input() okText: string;
  @Input() cancelText: string;
  @Output() valueEmitted = new EventEmitter<object>();

  constructor() {
    this.okText = "OK";
    this.cancelText = "Cancel";
  }

  ngOnInit() {
  }

  emitValue(value: string) {
    if (this.showValue) {
      console.log(`Emitting { name: ${value}, value: ${this.valuevalue}}`);
      this.valueEmitted.emit({ name: value, value: this.valuevalue } );
    } else {
      console.log(`Emitting {value: "${value}"}`);
      this.valueEmitted.emit( { name: value });
    }
  }

}
/*
<app-dialog [name]="name()"
            [template]="'Name'"
            [okText]="okButtonText"
            [value]="fieldValue"
            (valueEmitted)="updateSelected($event)"
            [showPrompt]="showDialog"
            [showValue]="showPropertyDialog"
            [showChild]="addingChild">
</app-dialog>
*/
